class Logger {
  debug(...args) {

  }

  info(...args) {
    console.log(...args);
  }

  warn(...args) {
    console.warn(...args);
  }

  error(...args) {
    console.error(...args);
    const stack = args?.[0]?.stack ?? args?.[0]?.error?.stack;
    if (stack) {
      console.error(stack);
    }
  }
}

const default_log = new Logger();
window.log = default_log;

export default Logger;

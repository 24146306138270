self.fs_shim = {
  promises: {}
};

self.later = null;

const _setInterval = setInterval;

class IntervalHandle {
  constructor(...args) {
    this._handle = _setInterval(...args);
  }

  valueOf() {
    return this._handle;
  }

  unref() {
    // does nothing
  }
}

self.setInterval = (...args) => new IntervalHandle(...args);

/*
self.setInterval = (...args) => {
  const handle = _setInterval(...args);
  return {
    unref() {},
    handle
  };
};

const _clearInterval = clearInterval;
self.clearInterval = handle => {
  _clearInterval(handle ? handle.handle : handle);
};
*/

self.cors_import = path => import(/* @vite-ignore */ path);
